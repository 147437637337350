<template>
  <div class="item" v-if="product">
    <div @click="togglePay" :class="['checkbox', {active: selectedTypes.inCart}]" v-if="cart">

    </div>
    <div class="gallery" v-if="product.images.length">
      <div class="img">
        <img loading="lazy" :src="URL+product.images[0].image" alt="">
      </div>
    </div>

    <div class="info">
      <div class="title">{{ product.title }}</div>
      <div :class="['colors', {disabled: product.positions[0].withoutColor}]" v-if="product.positions">
        <div class="subtitle subtitle-xs">Цвет:</div>
        <div class="colors-types">
          <div
            v-for="position in product.positions"
            :key="position.id"
            :style="{background: position.color}"
            :class="['color', {active: position.id === selectedTypes.color, disabled: cart && position.id !== selectedTypes.color}]"
            @click="changeColor(position)"
          >
            <svg width="16" height="12" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.21269 10.6525L12.4794 21.9999L30.2127 2.3999" stroke="#242A38" stroke-width="4.2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="sizes">
        <div class="subtitle subtitle-xs">Размер:</div>
        <div class="sizes-types">
          <div
            v-for="size in selectedTypes.item.size"
            :key="size.id"
            :class="['size',{active: size.id === selectedTypes.size, disabled: size.count <= 0, disabledCart: cart && size.id !== selectedTypes.size}]"
            @click="changeSize(size)"
          >
            {{ size.title }}
            <img v-if="size.count <= 0" src="@/assets/img/zlo.svg" alt="">
          </div>
        </div>
      </div>
      <div class="price">
        {{ totalPrice }}
      </div>
    </div>

    <div class="counter">
      <div @click="decrement" class="decrement">
        <svg width="60" height="60" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="120" height="120" rx="60" fill="#393F42" fill-opacity="0.5"/>
          <path d="M40 60H83.75" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="count">{{ count }}</div>
      <div @click="increment" class="increment">
        <svg width="60" height="60" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="120" height="120" rx="60" fill="#393F42" fill-opacity="0.5"/>
          <path d="M40 61.875H83.75" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M61.875 40L61.875 83.75" stroke="white" stroke-width="5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
      <span>{{ selectedTypes.available > 0 ? 'Доступно: ' + selectedTypes.available : 'Размер недоступен' }}</span>
    </div>
    <div @click="deleteItem" class="delete" v-if="cart">
      <svg width="60" height="60" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2.5" y="2.5" width="115" height="115" rx="57.5" fill="white" stroke="#393F42" stroke-width="5"/>
        <path
          d="M42.4999 80.4167C42.4999 83.625 45.1249 86.25 48.3333 86.25H71.6666C74.8749 86.25 77.4999 83.625 77.4999 80.4167V45.4167H42.4999V80.4167ZM80.4166 36.6667H70.2083L67.2916 33.75H52.7083L49.7916 36.6667H39.5833V42.5H80.4166V36.6667Z"
          fill="#242A38"/>
      </svg>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OneProduct",
  props: {
    product: {
      type: Object,
      required: true,
      default: null
    },
    selectedParams: {
      type: Object,
      required: false,
      default: null
    },
    cart: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      selected: null,
      count: 1,
    }
  },
  computed: {
    totalPrice() {
      return Number(this.product.price * this.count).toLocaleString('ru')
    },
    selectedTypes() {
      return this.selected ? this.selected : this.selectedParams
    },
    ...mapGetters(['URL'])
  },
  methods: {
    changeSize(size) {
      if (this.cart) {
        return
      }
      this.selected.available = size.count
      this.selected.size = size.id
      this.count = 1;
    },
    increment() {
      if (this.selected.available > this.count) {
        this.count++
      }
    },
    decrement() {
      if (this.count > 1) {
        this.count--
      }
    },
    changeColor(color) {
      if (this.cart) {
        return
      }
      this.selected.item = color;
      this.selected.color = color.id;
      this.selected.size = color.size[0].id;
      this.selected.available = color.size[0].count
      if (!color.size[0].count) {
        this.count = 0;
      } else {
        this.count = 1;
      }
    },
    deleteItem() {
      this.$emit('deleteItem', this.selectedParams)
    },
    togglePay() {
      if (this.selected.available > 0) {
        this.selected.inCart = !this.selected.inCart
      } else {
        return
      }
    }
  },
  mounted() {
    this.selected = this.selectedParams ? this.selectedParams : null;
  },
  watch: {
    count() {
      if (this.cart) {
        this.selected.count = this.count
      } else {
        this.$emit('count', this.count)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.checkbox {
  width: 60px;
  height: 60px;
  border: 3px solid $primary;
  border-radius: 25px;
  position: relative;
  align-self: center;

  &.active {
    background: $primary url('@/assets/img/check-w.svg') center/ 38px no-repeat;
  }
}

.delete {
  position: absolute;
  right: 12px;
  top: 125px;
}

.item {
  display: flex;
  gap: 45px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.gallery {
  flex: 0 0 177px;
}

.img {
  width: 177px;
  height: 217px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.title {
  font-size: 45px;
  margin-bottom: 22px;
  text-align: left;
}

.info {
  flex: 0 1 295px;
}

.colors {
  display: flex;
  align-items: center;
  gap: 54px;

  &.disabled {
    display: none;
  }

  .subtitle {
    position: relative;
    top: 5px;
  }
}

.colors-types {
  display: flex;
  align-items: center;
  gap: 10px;

  .color {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    &.active {
      svg {
        display: block;
      }
    }

    &.disabled {
      display: none;
    }

  }
}

.sizes {
  display: flex;
  align-items: center;
  gap: 36px;
  margin-top: 16px;

  .subtitle {
    position: relative;
    top: 5px;
  }
}

.sizes-types {

  display: flex;
  align-items: center;
  gap: 10px;

  .size {
    padding: 0 8px;
    min-width: 28px;
    min-height: 28px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
    position: relative;

    &.active {
      color: #fff;
      background: $primary;
    }

    &.disabled {
      color: $gray-light;
      background: #fff;
      pointer-events: none;

      img {
        width: 29px;
        height: 29px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.disabledCart {
      display: none;
    }
  }
}

.price {
  margin-top: 21px;
  font-weight: 700;
  font-size: 45px;

  &::after {
    content: "\20BD";
    padding-left: 8px;
  }
}

.counter {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;

  span {
    position: absolute;
    bottom: -40px;
    left: 10px;
    font-size: 19px;
    width: 100%;
  }
}

.decrement {
  width: 60px;
  height: 60px;
}

.count {
  font-size: 35px;
}

.increment {
  width: 60px;
  height: 60px;
}
</style>

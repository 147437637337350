<template>
  <header class="header">
    <div class="container">
      <GButton
        color="outlined"
        size="l"
        @click="$router.go(-1)"
        class="header-btn"
      >
        Назад
      </GButton>

      <GButton
        color="outlined"
        size="l"
        @click="$router.push({name: 'cart'})"
        class="header-btn"
        v-if="$route.name !== 'cart'"
      >
        <svg width="22" height="22" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.5125 45C12.2738 45 11.2139 44.5598 10.3325 43.6793C9.44973 42.7973 9.00832 41.7375 9.00832 40.5C9.00832 39.2625 9.44973 38.2027 10.3325 37.3207C11.2139 36.4402 12.2738 36 13.5125 36C14.7511 36 15.8111 36.4402 16.6924 37.3207C17.5752 38.2027 18.0166 39.2625 18.0166 40.5C18.0166 41.7375 17.5752 42.7973 16.6924 43.6793C15.8111 44.5598 14.7511 45 13.5125 45ZM36.0333 45C34.7946 45 33.7347 44.5598 32.8534 43.6793C31.9705 42.7973 31.5291 41.7375 31.5291 40.5C31.5291 39.2625 31.9705 38.2027 32.8534 37.3207C33.7347 36.4402 34.7946 36 36.0333 36C37.2719 36 38.3327 36.4402 39.2155 37.3207C40.0968 38.2027 40.5375 39.2625 40.5375 40.5C40.5375 41.7375 40.0968 42.7973 39.2155 43.6793C38.3327 44.5598 37.2719 45 36.0333 45ZM9.45874 4.5H42.6769C43.5402 4.5 44.1971 4.884 44.6475 5.652C45.0979 6.4215 45.1167 7.2 44.7038 7.9875L36.7089 22.3875C36.296 23.1375 35.742 23.7188 35.0469 24.1313C34.3532 24.5438 33.5935 24.75 32.7678 24.75H15.9898L13.5125 29.25H40.5375V33.75H13.5125C11.8234 33.75 10.5472 33.009 9.68395 31.527C8.82065 30.0465 8.78311 28.575 9.57134 27.1125L12.6117 21.6L4.50416 4.5H0V0H7.31926L9.45874 4.5Z"
            fill="#242A38"/>
        </svg>
        Корзина
      </GButton>
      <GButton
        color="outlined"
        size="l"
        @click="removeCart"
        class="header-btn"
        v-if="cartProd"
      >
        Удалить товары
      </GButton>
    </div>
  </header>
</template>

<script>
import GButton from "@/components/UI/GButton.vue";

export default {
  name: "Header",
  components: {GButton},
  methods: {
    removeCart() {
    this.$emit('removeCart')
    }
  },
  computed: {
    cartProd(){
      return this.$route.name === 'cart' && localStorage.getItem('cart').length > 2
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.header-btn {
  width: 210px;
}

</style>

import {createStore} from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    // URL: 'https://gsp.useful.su',
    URL: 'https://p.gaz-magaz.ru',
    SOCKET: 'ws://127.0.0.1:51654/GazMagaz',
    categories: null,
    fetching: false,
    hide: false,
  },
  getters: {
    URL: (state) => state.URL,
    SOCKET: (state) => state.SOCKET,
    categories: (state) => state.categories,
    fetching: (state) => state.fetching,
    hide: (state) => state.hide
  },
  mutations: {
    GET_CATEGORIES(state, array) {
      state.categories = array
    },
    SET_FETCHING(state, boolean) {
      state.fetching = boolean
    },
    SET_HIDE(state, boolean) {
      state.hide = boolean
    },
  },
  actions: {
    async fetchCategories({state, commit}, payload) {

      try {
        const response = await axios({
          baseURL: state.URL + '/api/categories',
          headers: {
            'Accept': 'application/json',
          },
          method: 'GET',
        })
        if (response.data.length) {
          commit('GET_CATEGORIES', response.data)
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    setFetching({commit}, payload) {
      commit('SET_FETCHING', payload)
    },
    setHide({commit}, payload) {
      commit('SET_HIDE', payload)
    }
  },
})

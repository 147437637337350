<template>
  <main class="main">
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="">
    </div>
    <div class="main-swiper">
      <MainSwiper/>
    </div>
    <div class="main-footer">
      <img src="@/assets/img/main-footer.png" alt="">
      <div class="main-footer-logo">
        <img src="@/assets/img/logo.png" alt="">
      </div>
      <div class="text">
        На сайте <a href="#">ГАЗМАГАЗ.РФ</a>
      </div>
    </div>
  </main>
</template>

<script>

import MainSwiper from "@/components/mainPage/MainSwiper.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "mainPage",
  components: {Footer, MainSwiper},
  methods: {},
  data() {
    return {
      post: '',
      house: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/fonts.scss";
@import "@/assets/scss/variables.scss";

.main-footer {
  position: relative;
  padding-top: 626px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-footer-logo {
  position: absolute;
  top: 145px;
  left: 82px;
  width: 229px;
  height: 75px;
}

.text {
  font-family: 'Bebas Neue', serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: $primary;
  position: absolute;
  top: 235px;
  left: 90px;

  a {
    color: $secondary;
    text-decoration: underline;
  }
}
</style>

<template>
  <div class="main">
    <Header/>
    <div @click="$router.push({name: 'home'})" class="logo">
      <img src="@/assets/img/logo.png" alt="">
    </div>
    <div class="container">

      <h1 class="title">{{ pageTitle }}</h1>
      <div class="catalog" v-if="productsList.length > 0">
        <div
          v-for="cat in productsList"
          :key="cat.id"
          class="category-item"
          @click="openItem(cat)"
        >
          <div class="left">
            <div class="subtitle">{{ cat.title }}</div>
            <GButton
              color="primary"
            >
              Подробнее
            </GButton>
          </div>
          <div class="img" v-if="cat.images.length">
            <img loading="lazy" :src="URL + cat.images[0].image" alt="">
          </div>
          <div class="price">
            {{ cat.price.toLocaleString('ru') }}
          </div>
        </div>
        <div v-if="!end" ref="intersection" class="intersection"></div>
      </div>
      <div v-if="!fetching && productsList.length <= 0" class="empty">- Каталог пуст -</div>
      <div v-if="fetching" class="empty">Идет загрузка, подождите...</div>

    </div>
    <Footer/>
    <transition name="fade">
      <ProductPopup
        @openItem="openItem"
        v-if="selectedItem"
        @close="selectedItem = null"
        :item="selectedItem"
        :otherProducts="productsList"
      />
    </transition>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ProductPopup from "@/components/product/productPopup.vue";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "catalogPage",
  components: {ProductPopup, Footer, Header},
  data() {
    return {
      productsList: [],
      selectedItem: null,
      page: 0,
      end: false,
    }
  },
  methods: {
    openItem(cat) {
      this.item = null
      this.selectedItem = cat
    },
    async fetchProducts() {
      this.page += 1;
      await this.setFetching(true)
      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/json',
          },
          method: 'GET',
        })
        if (response.data.length) {
          this.productsList = [...this.productsList, ...response.data]
          if (response.data.length < 9) {
            this.end = true;
          }
        } else {
        }
      } catch (e) {
        console.log(e)
      } finally {
        await this.setFetching(false)
      }
    },
    ...mapActions(['setFetching'])

  },
  computed: {
    pageTitle() {
      const path = +this.$route.params.id;
      if (this.categories) {
        const route = this.categories.find(i => i.id === path)
        if (route) {
          return this.categories.find(i => i.id === path).title
        } else {
          return 'Все категории'
        }
      } else {
      }
    },
    baseUrl() {
      let url = `${this.URL}/api/products?page=${this.page}&itemsPerPage=9`;
      if (!isNaN(this.$route.params.id)) {
        url += `&categories.id=${this.$route.params.id}`
      }
      return url
    },
    ...mapGetters(['URL', 'fetching', 'categories'])
  },
  async mounted() {
    await this.fetchProducts()
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    }
    const callback = (entries, observer) => {
      if (entries[0].isIntersecting && !this.end) {
        this.fetchProducts()
      }
    }
    const observer = new IntersectionObserver(callback, options);
    if (this.$refs.intersection) {
      observer.observe(this.$refs.intersection)
    }

  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.container {
  padding-right: 25px;
}

.title {
  padding-top: 160px;
  margin-bottom: 75px;
}

.catalog {
  width: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-height: 1350px;
  padding-right: 25px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f9f9fd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $secondary;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #f9f9fd;
  }
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 47px;
  background: #fff;
  flex-direction: column;
  min-height: 405px;
  position: relative;
  max-width: 294px;

  .left {
    order: 2;
    width: 100%;
  }

  .btn {
    width: 145px;
    margin: 0 auto;
  }

  .subtitle {
    margin-bottom: 18px;
    text-align: center;
  }

  .img {
    width: 148px;
    height: 248px;
    position: relative;
    margin-bottom: 12px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.price {
  top: 0;
  right: 0;
  position: absolute;
  padding: 12px 15px;
  background: $primary;
  color: #fff;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0.02em;

  &::after {
    content: "\20BD";
    padding-left: 6px;

  }
}

.intersection {
  height: 30px;
}
</style>

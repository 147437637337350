<template>
  <div :class="['form-control',{error: error, active: active}]" ref="label">
    <label
      :for="id"
    >
      {{ label }}
    </label>
    <input
      @blur="blur"
      @focus="focus"
      @input="input"
      :id="id"
      :name="id"
      type="text"
      v-if="!phone"
    >
    <input
      @blur="blur"
      @focus="focus"
      @input="input"
      :id="id"
      :name="id"
      type="text"
      v-phone
      v-else
    >
    <div v-if="error" class="error-msg">
      Поле обязательно
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "GInput",
  data() {
    return {
      error: false,
      active: false,
    }
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    input({target}) {
      this.$emit('update:modelValue', target.value)
    },
    focus() {
      this.setHide(true)
      this.active = true;
    },
    blur({target}) {
      this.setHide(false)
      if (this.required && !target.value) {
        this.error = true;
      } else if (target.value) {
        this.active = true;
        this.error = false;
      } else {
        this.error = false;
        this.active = false;
      }

    },
    ...mapActions(['setHide'])
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.form-control {
  position: relative;

  &.active {
    label {
      font-size: 14px;
      top: -6px;
      transform: translateY(0);
      padding: 3px;
      background: #fff;
      color: $primary;
    }

    input {
      border-color: $primary;
    }
  }

  &.error {
    input {
      border-color: $error;
    }

    label {
      color: $error;
    }
  }


  label {
    font-size: 15px;
    color: $gray-light;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.1s, top 0.1s, left 0.1s, font-size 0.3s;
  }

  input {
    font-size: 15px;
    color: $primary;
    padding-left: 20px;
    border: 2px solid $gray-light;
    width: 100%;
    height: 60px;
    transition: border-color 0.3s;
  }
}


.error-msg {
  position: absolute;
  left: 0;
  bottom: -17px;
  color: $error;
  font-size: 12px;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
import MainPage from "@/views/mainPage.vue";
import Cart from "@/views/Cart.vue";
import storePage from "@/views/storePage.vue";
import catalogPage from "@/views/catalogPage.vue";
import aboutPage from "@/views/aboutPage.vue";
import OneClick from "@/views/OneClick.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/catalog',
    name: 'store',
    component: storePage
  },
  {
    path: '/catalog/:id',
    name: 'catalog',
    component: catalogPage
  },
  {
    path: '/about',
    name: 'about',
    component: aboutPage
  },
  {
    path: '/buy/:id',
    name: 'oneClick',
    component: OneClick
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

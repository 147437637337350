<template>
  <footer class="footer">
    <div class="container">
      <div class="timer">
        <div class="timer-title">До возврата на главный экран:</div>
        <div class="timer-time">
          <span>{{ minuteOne }}</span>
          <span>{{ minuteTwo }}</span>
          <p>:</p>
          <span>{{ secondOne }}</span>
          <span>{{ secondTwo }}</span>
        </div>
      </div>
      <GButton v-if="!hideBtn" @click="$router.push({name: 'about'})" color="outlined" size="l">О магазине</GButton>
    </div>
  </footer>
</template>

<script>


export default {
  name: "Footer",
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      secondOne: 0,
      secondTwo: 0,
      minuteOne: 0,
      minuteTwo: 3,
      interval: null,
      time: 0,

    }
  },
  methods: {
    resetTimer() {
      this.time = 0;
      this.secondOne = 0;
      this.secondTwo = 0;
      this.minuteOne = 0;
      this.minuteTwo = 3;
    },
    inactivityTime() {
      this.interval = setInterval(() => {
        console.log('tick')
        this.time++
        if (this.secondTwo === 0) {
          this.secondTwo = 10
          if (this.secondOne === 0) {
            this.secondOne = 6
            if (this.minuteTwo === 0) {
              this.minuteTwo = 3
              this.minuteOne = 0
            }
            this.minuteTwo--
          }
          this.secondOne--
        }
        this.secondTwo--

        if (this.time >= 180) {
          this.resetTimer();

          if (this.$route.name !== 'home') {
            this.$router.push({name: 'home'})
          }
          localStorage.removeItem('cart');
          localStorage.setItem('cart', JSON.stringify([]));
        }
      }, 1000);
      // сюда можно добавить любой ивент.
    },

  },
  mounted() {
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    document.addEventListener('touch', this.resetTimer);
    this.inactivityTime()
  },
  beforeUnmount() {
    console.log('del')
    clearInterval(this.interval)
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('keypress', this.resetTimer);
    document.removeEventListener('touch', this.resetTimer);
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 18px;
  left: 0;
  width: 100%;
  z-index: 33;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer {
  display: flex;
  align-items: center;
}

.timer-title {
  font-size: 25px;
  margin-right: 45px;
}

.timer-time {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(57, 63, 66, 0.1);
    width: 35px;
    height: 43px;
    font-weight: 700;
  }

  p {
    font-size: 25px;
    padding: 0 18px;
    position: relative;
    top: -5px;
    animation: timer 2s infinite linear;
  }
}

.btn {
  flex: 0 0 210px;
}

@keyframes timer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

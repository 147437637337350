<template>
  <button
    :class="['btn', {direct: direct}, color, size, {loading: loading}]"
  >
    <span v-if="loading">
      <div class="lds-dual-ring"></div>
    </span>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: "GButton",
  props: {
    direct: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'default'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/fonts.scss";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  font-weight: 500;
  border-radius: 800px;
  width: 100%;
  gap: 16px;

  &.disabled {
    background: $gray;
    pointer-events: none;
    color: #fffF;
  }
}

.primary {
  background: $primary;
  color: #fff;
}

.outlined {
  background: transparent;
  border: 2px solid;
}

.direct {
  border-radius: 8px;
}

.default {
  height: 45px;
}

.l {
  height: 60px;
  font-size: 20px;
}

.xl {
  height: 70px;
  font-size: 21px;
}

.xs {
  height: 27px;
  font-size: 12px;

  .lds-dual-ring {
    width: 24px;
    height: 24px;
  }

  .lds-dual-ring:after {
    width: 16px;
    height: 16px;
    border-width: 4px;
  }
}

.loading {
  pointer-events: none;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bebas {
  color: $third;
  background: #fff;
  border: 3px solid;
  border-radius: 100px;
  font-weight: 700;
  font-size: 50px;
  font-family: 'Bebas Neue', serif;
  width: 450px;
  height: 105px;
  text-transform: uppercase;
}

</style>

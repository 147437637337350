<template>
  <div class="swiper" ref="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide" v-for="image in images" :key="image.id">
        <img :src="image.src" alt="">
      </div>
    </div>
    <GButton @click="toStore" color="bebas" class="slide-btn"><span>В каталог</span></GButton>
    <div class="swiper-timing">
      <div class="timing-indicator"></div>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper, {EffectFade, Autoplay} from 'swiper';
// import Swiper styles
import 'swiper/css/bundle';
import GButton from "@/components/UI/GButton.vue";

export default {
  name: "MainSwiper",
  components: {GButton},
  data() {
    return {
      swiper: this.$refs.swiper,
      images: [
        {
          id: 1,
          src: require('@/assets/img/main-1.png')
        },
        {
          id: 2,
          src: require('@/assets/img/main-2.png')
        },
      ]
    }
  },
  methods: {
    toStore() {
      this.$router.push({name: 'store'})
    },
    createSwiper() {
      const swiper = new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        modules: [EffectFade, Autoplay],
        loop: true,
        autoHeight: false,
        autoplay: {
          delay: 5000,
          waitForTransition: false,
        },
        effect: "fade",
      });
    }
  },
  mounted() {
    this.createSwiper();
  },
  updated() {
    this.createSwiper();
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.swiper {
  position: relative;
}

.swiper-slide {
  height: 1294px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }
}

.swiper-timing {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11px;
  background: #ACCFE5;
  z-index: 2;
}

.timing-indicator {
  height: 11px;
  background: $secondary;
  width: 0%;
  //transition: all 0.3s ease 0s;
  animation: loading 5s ease infinite;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.slide-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: 73px;

  span {
    position: relative;
    top: 5px;
  }
}
</style>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {}
  },
  methods: {
    initLocal() {
      const cart = localStorage.getItem('cart') ? '' : localStorage.setItem('cart', JSON.stringify([]))
    },
    ...mapActions(['fetchCategories'])
  },
  async mounted() {
    await this.fetchCategories();
    this.initLocal()
  }
}
</script>
<style lang="scss">
.logo {
  position: fixed;
  left: calc(50% - 10px);
  top: 25px;
  transform: translateX(-50%);
  width: 225px;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <main class="main">
    <div @click="$router.push({name: 'home'})" class="logo">
      <img src="@/assets/img/logo.png" alt="">
    </div>
    <Header/>
    <div class="container">
      <div class="title">Вопросы и ответы</div>

      <div class="content">
        <div class="spoilers">
          <div v-for="item in faq" :key="item.id" class="spoiler-parent">
            <div class="spoiler-title">{{ item.question }} <span></span></div>
            <div class="spoiler-content" v-html="item.answer"></div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import spollers from "@/assets/js/spollers";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "aboutPage",
  components: {Footer, Header},
  data() {
    return {
      faq: [],
    }
  },
  methods: {
    async getFAQ() {
      try {
        const response = await axios({
          baseURL: this.URL + '/api/faqs',
          headers: {
            'Accept': 'application/json',
          },
          method: 'GET',
        })
        this.faq = response.data;
      } catch (e) {
        console.log(e)
      } finally {
      }
    }
  },
  computed: {
    ...mapGetters(['URL'])
  },
  async mounted() {
    await this.getFAQ()
    setTimeout(() => {
      spollers()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.title {
  margin: 160px 0 30px;

}

.content {
  width: 100%;
  height: 1444px;
  background: #ffffff;
  padding: 95px 125px;
}

.spoilers {
  max-height: 1254px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.spoiler-parent {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray;
}

.spoiler-parent + .spoiler-parent {
  margin-top: 35px;
}

.spoiler-title {
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  position: relative;

  &.active {
    span {
      background: #fff;

      &:before,
      &:after {
        background: $primary;
        transform: rotate(0deg);
      }
    }
  }

  span {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 29px;
    height: 29px;
    display: block;
    border-radius: 50%;
    border: 3px solid $primary;
    background: $primary;
    pointer-events: none;
    transition: all 0.3s;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 2px;
      background: #fff;
      top: 10px;
      left: 4px;
      transition: all 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 16px;
      height: 2px;
      background: #fff;
      top: 10px;
      left: 4px;
      transform: rotate(90deg);
      transition: all 0.3s;
    }
  }
}

.spoiler-content {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: $gray;
  margin-top: 20px;
}
</style>

<template>
  <div class="popup">
    <transition name="fade">
      <div class="alert" v-if="showAlert">Товар добавлен в корзину</div>
    </transition>
    <div class="popup-bg"></div>
    <div class="popup-body">
      <div class="popup-header">
        <div class="logo">
          <img src="@/assets/img/logo.png" alt="">
        </div>
        <GButton
          size="l"
          color="outlined"
          @click="$emit('close')"
        >
          Закрыть
        </GButton>
      </div>
      <div class="popup-content" v-if="item">
        <div class="gallery" v-if="item.images.length">
          <div class="gallery-left">
            <div
              @click="clickImg(img,i)"
              v-for="(img,i) in item.images"
              :class="['left-img', {active: selected.imgIndex === i}]"
            >
              <img
                loading="lazy"
                :src="URL+img.image" alt=""
              >
            </div>
          </div>
          <div class="gallery-right">
            <div class="right-img">
              <img loading="lazy" :src="selectedImg" alt="">
            </div>
          </div>
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="info">
          <div :class="['colors', {disabled: item.positions[0].withoutColor}]" v-if="item.positions">
            <div class="subtitle subtitle-xs">Цвет:</div>
            <div class="colors-types">
              <div
                v-for="position in item.positions"
                :key="position.color"
                :style="{background: position.color}"
                :class="['color', {active: position.id === selected.color, disabled: position.withoutColor}]"
                @click="changeColor(position)"
              >
                <svg width="16" height="12" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.21269 10.6525L12.4794 21.9999L30.2127 2.3999" stroke="#242A38" stroke-width="4.2"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="sizes">
            <div class="subtitle subtitle-xs">Размер:</div>
            <div class="sizes-types">
              <div
                v-for="size in selected.item.size"
                :key="size.id"
                :class="['size',{active: size.id === selected.size, disabled: size.count <=0}]"
                @click="changeSize(size)"
              >
                {{ size.title }}
                <img v-if="size.count <=0" src="@/assets/img/zlo.svg" alt="">
              </div>
              <span>{{ selected.available > 0 ? 'Доступно: ' + selected.available : 'Размер недоступен' }}</span>
            </div>
          </div>
          <div class="about" v-if="item.description">
            <div class="subtitle subtitle-xs">Описание:</div>
            <p v-html="item.description"></p>
          </div>
          <div class="price">
            {{ Number(item.price).toLocaleString('ru') }}
          </div>
          <div class="actions">
            <GButton :class="{disabled: selected.available < 1}" @click="oneClick" size="xl" color="outlined">Купить
              в один клик
            </GButton>
            <GButton v-if="item.type !== 'certificate'" :class="{disabled: selected.available < 1}" @click="cartClick" size="xl" color="primary">
              <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5125 45C12.2738 45 11.2139 44.5598 10.3325 43.6793C9.44973 42.7973 9.00832 41.7375 9.00832 40.5C9.00832 39.2625 9.44973 38.2027 10.3325 37.3207C11.2139 36.4402 12.2738 36 13.5125 36C14.7511 36 15.8111 36.4402 16.6924 37.3207C17.5752 38.2027 18.0166 39.2625 18.0166 40.5C18.0166 41.7375 17.5752 42.7973 16.6924 43.6793C15.8111 44.5598 14.7511 45 13.5125 45ZM36.0333 45C34.7946 45 33.7347 44.5598 32.8534 43.6793C31.9705 42.7973 31.5291 41.7375 31.5291 40.5C31.5291 39.2625 31.9705 38.2027 32.8534 37.3207C33.7347 36.4402 34.7946 36 36.0333 36C37.2719 36 38.3327 36.4402 39.2155 37.3207C40.0968 38.2027 40.5375 39.2625 40.5375 40.5C40.5375 41.7375 40.0968 42.7973 39.2155 43.6793C38.3327 44.5598 37.2719 45 36.0333 45ZM9.45874 4.5H42.6769C43.5402 4.5 44.1971 4.884 44.6475 5.652C45.0979 6.4215 45.1167 7.2 44.7038 7.9875L36.7089 22.3875C36.296 23.1375 35.742 23.7188 35.0469 24.1313C34.3532 24.5438 33.5935 24.75 32.7678 24.75H15.9898L13.5125 29.25H40.5375V33.75H13.5125C11.8234 33.75 10.5472 33.009 9.68395 31.527C8.82065 30.0465 8.78311 28.575 9.57134 27.1125L12.6117 21.6L4.50416 4.5H0V0H7.31926L9.45874 4.5Z"
                  fill="#fff"/>
              </svg>
              {{ inCart ? 'Открыть корзину' : 'В корзину' }}
            </GButton>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        - Произошла ошибка -
      </div>
      <div class="popup-footer" v-if="otherProductsWithoutSelected.length">
        <div class="subtitle">С этим товаром покупают</div>
        <OtherProductsSwiper @openItem="openItem" :catalog="otherProductsWithoutSelected"/>
      </div>
    </div>
  </div>
</template>

<script>

import OtherProductsSwiper from "@/components/product/otherProductsSwiper.vue";
import GButton from "@/components/UI/GButton.vue";
import {mapGetters} from "vuex";
import router from "@/router";

export default {
  name: "productPopup",
  components: {GButton, OtherProductsSwiper},
  props: {
    item: {
      type: Object,
      required: true,
    },
    otherProducts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showAlert: false,
      inCart: false,
      selected: {
        item: this.item.positions[0],
        color: this.item.positions[0].id,
        size: this.item.positions[0].size[0].id,
        img: '',
        imgIndex: 0,
        available: this.item.positions[0].size[0].count,
      },
      cartStore: [],
    }
  },
  methods: {
    changeSize(size) {
      this.selected.available = size.count
      this.selected.size = size.id
      this.inCart = false;
    },
    clickImg(img, i) {
      this.selected.img = img;
      this.selected.imgIndex = i;
    },
    changeColor(item) {
      this.selected.item = item;
      this.selected.color = item.id;
      this.selected.size = item.size[0].id;
      this.selected.available = item.size[0].count
      this.inCart = false;
    },
    openItem(cat) {
      this.inCart = false;
      this.$emit('openItem', cat)
      this.selected = {
        item: cat.positions[0],
        color: cat.positions[0].id,
        size: cat.positions[0].size[0].id,
        img: '',
        imgIndex: 0,
        available: cat.positions[0].size[0].count
      }

    },
    oneClick() {
      this.$router.push({
        name: 'oneClick',
        params: {id: this.item.id},
        query: {color: this.selected.color, size: this.selected.size}
      })
    },
    showAlertFunc() {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false
      }, 4000)
    },
    cartClick() {
      if (this.inCart) {
        this.$router.push({name: 'cart'})
      } else {

        this.showAlertFunc();
        this.inCart = true;
        const uniq = String(this.item.id) + String(this.selected.color) + String(this.selected.size)
        const obj = {
          uniq: uniq,
          id: this.item.id,
          color: this.selected.color,
          size: this.selected.size,
        };
        this.cartStore = this.cartStore.filter(item => item.uniq !== obj.uniq)
        this.cartStore.push(obj)
        localStorage.removeItem('cart')
        localStorage.setItem('cart', JSON.stringify(this.cartStore))
      }
    },
  },
  computed: {
    selectedImg() {
      return this.selected.img ? this.URL + this.selected.img.image : this.URL + this.item.images[0].image
    },
    otherProductsWithoutSelected() {
      return this.otherProducts.filter(item => item.id !== this.item.id)
    },
    ...mapGetters(['URL'])
  },
  mounted() {
    this.cartStore = Array.from(JSON.parse(localStorage.getItem('cart')))
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(40px);
}

.popup-body {
  position: relative;
  z-index: 1;
  width: 980px;
  display: flex;
  flex-direction: column;
  height: 1600px;
  padding: 35px 50px 95px;
  background: #fff;
}

.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;


  .btn {
    flex: 0 0 180px;
  }
}


.popup-content {
  flex: 1 1 auto;
  margin: 45px 70px;

  .title {
    margin: 50px 0 40px;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    width: 590px;
    margin: 0 auto;
  }

  .colors {
    flex: 0 1 283px;
    margin-right: auto;

    &.disabled {
      display: none;
    }
  }

  .colors-types {
    display: flex;
    gap: 10px;

    .color {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      border: 2px solid $primary;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: none;
      }

      &.active {
        svg {
          display: block;
        }
      }

    }
  }

  .sizes {
    flex: 1 0 290px;
  }

  .sizes-types {
    display: flex;
    gap: 10px;
    position: relative;

    span {
      position: absolute;
      bottom: -25px;
      left: 0;
    }

    .size {
      padding: 0 6px;
      min-width: 39px;
      min-height: 39px;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 17px;
      position: relative;

      &.active {
        color: #fff;
        background: $primary;
      }

      &.disabled {
        color: $gray-light;
        background: #fff;
        pointer-events: none;

        img {
          width: 41px;
          height: 41px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }


  .subtitle {
    text-align: left;
    margin-bottom: 10px;
  }


  .about {
    flex: 0 0 100%;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: $gray;

    p {
      max-height: 135px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .price {
    flex: 0 0 100%;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 0.02em;
  }

  .actions {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.popup-footer {
  .subtitle {
    text-align: left;
    margin-bottom: 16px;
  }
}

.price {

  &::after {
    content: "\20BD";
    padding-left: 8px;
  }

}

.gallery {
  display: flex;
  gap: 20px;
  width: 493px;
  margin: 0 auto;
}

.gallery-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0 0 82px;
  max-height: 490px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .left-img {
    position: relative;
    display: block;
    width: 100%;
    height: 102px;
    flex: 0 0 102px;

    &.active {
      border: 2px solid $secondary;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
}

.gallery-right {
  flex: 0 0 394px;
  height: 490px;

  .right-img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }

}

</style>

<template>
  <div class="main">
    <div @click="$router.push({name: 'home'})" class="logo">
      <img src="@/assets/img/logo.png" alt="">
    </div>
    <Header/>
    <div class="container">
      <h1 class="title">Категории</h1>
      <div class="category-list">
        <div
          v-for="cat in categories"
          :key="cat.id"
          class="category-item"
        >
          <div class="left">
            <div class="subtitle">{{ cat.title }}</div>
            <GButton
              @click="$router.push({name: 'catalog', params:{id: cat.id}})"
              color="primary"
            >
              Подробнее
            </GButton>
          </div>
          <div class="img">
            <img loading="lazy" :src="URL+cat.image" alt="">
          </div>
        </div>
        <div
          class="category-item"
        >
          <div class="left">
            <div class="subtitle">Все товары каталога</div>
            <GButton
              @click="$router.push({name: 'catalog', params:{id: 'all'}})"
              color="primary"
            >
              Подробнее
            </GButton>
          </div>
          <div class="img">
            <img loading="lazy" src="@/assets/img/main-footer.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {mapGetters} from "vuex";

export default {
  name: "storePage",
  components: {Footer, Header},
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['categories', 'URL'])
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding-top: 160px;
  margin-bottom: 75px;
}

.category-list {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 37px;
  background: #fff;
  flex: 1 1 45%;

  &:last-child {
    flex: 0 0 100%;
    padding: 20px 41px;

    .left {
      flex: 0 0 310px;
    }

    .subtitle {
      text-align: center;
    }

    .btn {
      margin: 0 auto;
      width: 145px;
    }

    .img {
      width: 520px;
      height: 248px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  .left {
    flex: 0 0 145px;
  }

  .subtitle {
    margin-bottom: 18px;
    text-align: left;
  }

  .img {
    width: 198px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

</style>

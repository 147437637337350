const spollers = function () {
  setTimeout(() => {
    let spoilers = document.querySelectorAll('.spoilers');
    if (spoilers.length > 0) {
      spoilers.forEach(spoilersParent => {
        let spoilersButtons = spoilersParent.querySelectorAll('.spoiler-title');
        spoilersButtons.forEach(button => {
          let content = button.nextElementSibling;
          button.addEventListener('click', function (e) {
            e.target.classList.add('disabled')
            setTimeout(() => {
              e.target.classList.remove('disabled')
            }, 500)
            // если надо, чтобы открывались все - то комментим этот блок
            spoilersButtons.forEach(button => {
              let content = button.nextElementSibling;
              if (button.classList.contains('active')) {
                content.classList.remove('active')
                button.classList.remove('active')
                _slideUp(content, 500);

              }
            });
            // вот до сюда вот)
            if (content.style.display == 'block') {
              content.classList.remove('active')
              e.target.classList.remove('active')
              _slideUp(content, 500);
            } else {
              content.classList.add('active')
              e.target.classList.add('active')
              _slideDown(content, 500);
            }
            e.preventDefault();
          });
        });

      });
    }
  }, 500)
}
let _slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    target.classList.remove('_slide');
  }, duration);
}
let _slideDown = (target, duration = 500) => {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;
  if (display === 'none')
    display = 'block';

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    target.classList.remove('_slide');
  }, duration);
}
let _slideToggle = (target, duration = 500) => {
  if (!target.classList.contains('_slide')) {
    target.classList.add('_slide');
    if (window.getComputedStyle(target).display === 'none') {
      return _slideDown(target, duration);
    } else {
      return _slideUp(target, duration);
    }
  }
}

export default spollers;

<template>
  <div class="swiper-container">
    <div class="swiper" ref="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div
          v-for="cat in catalog"
          :key="cat.id"
          class="category-item swiper-slide"
          @click="openItem(cat)"
        >
          <div class="left">
            <div class="subtitle">{{ cat.title }}</div>
            <GButton
              color="primary"
            >
              Подробнее
            </GButton>
          </div>
          <div class="img" v-if="cat.images.length">
            <img loading="lazy" :src="URL+cat.images[0].image" alt="">
          </div>
          <div class="price">
            {{ cat.price.toLocaleString('ru') }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Swiper JS
import Swiper from 'swiper';
import 'swiper/css/bundle';
import {mapGetters} from "vuex";

export default {
  props: {
    catalog: {
      type: Array,
      default: () => []
    }
  },
  name: "otherProductsSwiper",
  data() {
    return {
      swiper: this.$refs.swiper,
      swiperObj: null,
    }
  },
  methods: {
    createSwiper() {
      this.swiperObj = new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        loop: false,
        autoHeight: false,
        spaceBetween: 16,
        slidesPerView: 4.3,

      });
    },
    openItem(cat) {
      this.$emit('openItem', cat)
    }
  },
  computed: {
    ...mapGetters(['URL'])
  },

  mounted() {
    this.createSwiper();
  },
  updated() {
    this.createSwiper();
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.swiper {
  position: relative;
}

.swiper-wrapper {
  height: 260px;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 27px;
  background: #F1F1F1;
  flex-direction: column;
  position: relative;

  .left {
    order: 2;
    width: 100%;
  }

  .btn {
    flex: 0 0 90px;
    margin: 0 auto;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .img {
    width: 123px;
    height: 153px;
    position: relative;
    margin-bottom: 12px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.price {
  top: 0;
  right: 0;
  position: absolute;
  padding: 10px 12px;
  background: $primary;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;

  &::after {
    content: "\20BD";
    padding-left: 8px;
  }
}
</style>

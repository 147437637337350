<template>
  <div class="form-wrapper">
    <transition name="fade">
      <div class="alert" v-if="showAlert">{{ msg }}</div>
    </transition>
    <div class="row">
      <div class="subtitle subtitle-xs">Способ доставки</div>
      <div class="tabs">
        <div
          v-for="tab in tabs"
          :class="['tab', {active: tab.id === selectedTab}]"
          :key="tab.id"
          @click="changeTab(tab.id)"
        >
          {{ tab.title }}
        </div>
      </div>
      <div
        v-if="selectedTab === 1"
        class="tabs-content"
      >
        <div class="row">
          <form @submit.prevent="submit" class="form" autocomplete='on'>
            <div class="row">
              <div class="subtitle subtitle-xs">Адрес доставки</div>
              <div class="data">
                <GInput v-model="data.index" id="index" label="Индекс" required/>
                <GInput v-model="data.city" id="city" label="Населенный пункт" required/>
                <GInput v-model="data.street" id="street" label="Улица" required/>
                <GInput v-model="data.house" id="house" label="Дом" required/>
                <GInput v-model="data.appartment" id="apartment" label="Квартира" required/>
              </div>
            </div>
            <div class="row">
              <div class="subtitle subtitle-xs">Контактный номер телефона</div>
              <GInput phone v-model="data.phone" id="phone" label="Номер телефона" required/>
            </div>
          </form>
        </div>
      </div>
      <div
        v-if="selectedTab === 2"
        class="tabs-content"
      >
        <div class="row">
          <div class="subtitle subtitle-xs">Адрес пункта самовывоза</div>
          <div class="address">
            г. Санкт-Петербург, ул. Ташкентская, дом 3, корп. 3, лит. Б
            <span>ежедневно, с 9:00 до 18:00</span>
          </div>
        </div>
        <div class="row">
          <form @submit.prevent="submit" class="form">
            <div class="row">
              <div class="subtitle subtitle-xs">Контактный номер телефона</div>
              <GInput phone v-model="data.phone" id="phone" label="Номер телефона" required/>
            </div>
          </form>
        </div>
      </div>
      <div v-if="!certificate" class="row flex" style="margin-top: 48px">
        <div class="subtitle subtitle-xs">Промокод</div>
        <GInput v-model="promocode" id="promo" label="Промокод"/>
        <GButton
          :class="{disabled: promocode.length < 1}"
          :loading="fetching"
          @click.prevent="promo"
          color="primary"
          size="l"
          direct
        >
          Применить
        </GButton>
      </div>
      <div class="row" style="margin-top: 48px">
        <div class="title"> К оплате <span>{{ totalPrice }}</span></div>
        <div class="descr">Сумма доставки может быть рассчитана дополнительно. После оформления заказа с вами свяжется
          менеджер и уточнит детали.
        </div>
      </div>
      <div class="row">
        <GButton
          @click.prevent="submit"
          :loading="fetching"
          color="primary"
          size="xl"
          :class="{disabled: disabled}"
        >
          Перейти к оплате
        </GButton>
      </div>
    </div>
  </div>
</template>

<script>
import GInput from "@/components/UI/GInput.vue";
import GButton from "@/components/UI/GButton.vue";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "CartForm",
  props: ['price', 'certificate'],
  components: {GButton, GInput},
  data() {
    return {
      selectedTab: 1,
      tabs: [
        {
          id: 1,
          title: 'Доставка',
        },
        {
          id: 2,
          title: 'Самовывоз',
        },
      ],
      data: {
        phone: ''
      },
      promocode: '',
      successpromo: '',
      discount: null,
      showAlert: false,
      msg: ''
    }
  },

  methods: {
    changeTab(i) {
      this.data = {
        phone: '',
      };
      this.selectedTab = i
    },
    submit() {
      this.$emit('pay', {
        ...this.data,
        promo: this.successpromo,
        typeDelivery: Number(this.selectedTab) === 1 ? 'postal' : 'self'
      })
    },
    async promo() {
      await this.setFetching(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/promocodes/check',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          data: {
            code: this.promocode
          }
        })
        if (response.status === 200) {
          this.successpromo = this.promocode;
          this.discount = response.data;
          this.showAlert = true;
          this.msg = 'Промокод успешно применен'
          setTimeout(() => {
            this.showAlert = false;
          }, 3000)
        }
      } catch (e) {
        console.log(e)
        this.successpromo = '';
        this.promocode = '';
        this.discount = null;
        this.showAlert = true;
        this.msg = 'Промокод указан неверно или недоступен'
        setTimeout(() => {
          this.showAlert = false;
        }, 3000)
      } finally {
        await this.setFetching(false)

      }
    },
    ...mapActions(['setFetching'])
  },
  computed: {
    totalPrice() {
      if (this.discount) {
        if (this.discount.type === 'rub') {
          let num = Number(this.price - this.discount.discount);
          return num > 0 ? num.toLocaleString('ru') : 1
        } else if (this.discount.type === 'percent') {
          let num = Number(this.price - (this.price / 100 * this.discount.discount))
          return num > 0 ? num.toLocaleString('ru') : 1
        }
      } else {
        return Number(this.price).toLocaleString('ru')
      }

    },
    disabled() {
      if (this.selectedTab === 2) {

        return !Boolean(this.data.phone.length === 18 && this.price > 0)
      } else if (this.selectedTab === 1) {

        return !Boolean(this.data.phone.length === 18 && this.data.index && this.data.city && this.data.house && this.data.appartment && this.data.street && this.price > 0)
      }
    },
    ...mapGetters(['fetching', 'URL'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.descr {
  margin: 12px 0;
  font-size: 18px;
}

.form-wrapper {
  width: 605px;
}

.row + .row {
  margin-top: 35px;
}

.form-control {
  width: 400px;
}

.row.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 35px;
  border-bottom: 1px solid $gray;

  .subtitle {
    flex: 0 0 100%;
  }

  .form-control {
    flex: 0 0 403px;
    margin-right: 12px;
  }

  .btn {
    flex: 0 0 189px;
  }
}

.subtitle {
  text-align: left;
  margin-bottom: 12px;
}

.tabs {
  display: flex;
  gap: 60px;
}

.tab {
  color: $gray;
  position: relative;
  padding-left: 26px;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    width: 19px;
    height: 19px;
    display: block;
    border-radius: 50%;
    border: 2px solid;
  }

  &.active {
    color: $primary;

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 4px;
      display: block;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      background: $primary;
    }
  }
}

.tabs-content {
  margin-top: 35px;
}

.address {
  span {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: $gray;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;

  text-transform: uppercase;

  span {
    font-family: 'Roboto', serif;

    &::after {
      content: "\20BD";
      padding-left: 8px;
    }
  }
}

.data {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 10px;

  div:nth-child(1) {
    flex: 0 0 189px;
  }

  div:nth-child(2) {
    flex: 0 0 403px;
  }

  div:nth-child(3) {
    flex: 0 0 355px;
  }

  div:nth-child(4) {
    flex: 0 0 113px;
  }

  div:nth-child(5) {
    flex: 0 0 113px;
  }
}
</style>
